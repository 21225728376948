.o-pad {
    position: relative;
    background: #ddd;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    overflow: hidden;
}

.o-pad:after {
    content: '';
    position: absolute;
    z-index: 2;
    width: 20%;
    height: 20%;
    top: 50%;
    left: 50%;
    background: #ddd;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: none;
    transition: all 0.25s;
    cursor: pointer;
}

.o-pad:hover:after {
    width: 30%;
    height: 30%;
}

.o-pad a {
    display: block;
    position: absolute;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    width: 50%;
    height: 50%;
    text-align: center;
    transform: rotate(45deg);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.o-pad a:before {
    content: '';
    position: absolute;
    width: 60%;
    height: 60%;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.25s;
    cursor: pointer;
    display: none;
}

.o-pad a:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 5px;
    border-style: solid;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: all 0.25s;
}

.o-pad a.up {
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -20%) rotate(45deg);
    border-top-left-radius: 50%;
    z-index: 1;
}

.o-pad a.up:hover {
    background: linear-gradient(315deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.4) 100%);
}

.o-pad a.up:before {
    left: 57%;
    top: 57%;
}

.o-pad a.up:after {
    left: 53%;
    top: 53%;
    border-width: 0 13px 19px 13px;
    border-color: transparent transparent #aaa transparent;
}

.o-pad a.up:active:after {
    border-bottom-color: #333;
}

.o-pad a.down {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%) rotate(45deg);
    border-bottom-right-radius: 50%;
    z-index: 1;
}

.o-pad a.down:hover {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.4) 100%);
}

.o-pad a.down:before {
    left: 43%;
    top: 43%;
}

.o-pad a.down:after {
    left: 47%;
    top: 47%;
    border-width: 19px 13px 0px 13px;
    border-color: #aaa transparent transparent transparent;
}

.o-pad a.down:active:after {
    border-top-color: #333;
}

.o-pad a.left {
    top: 50%;
    right: 50%;
    transform: translate(-20%, -50%) rotate(45deg);
    border-bottom-left-radius: 50%;
    border: none;
}

.o-pad a.left:hover {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.4) 100%);
}

.o-pad a.left:before {
    left: 57%;
    top: 43%;
}

.o-pad a.left:after {
    left: 53%;
    top: 47%;
    border-width: 13px 19px 13px 0;
    border-color: transparent #aaa transparent transparent;
}

.o-pad a.left:active:after {
    border-right-color: #333;
}

.o-pad a.right {
    top: 50%;
    left: 50%;
    transform: translate(20%, -50%) rotate(45deg);
    border-top-right-radius: 50%;
    border: none;
}

.o-pad a.right:hover {
    background: linear-gradient(45deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.4) 100%);
}

.o-pad a.right:before {
    left: 43%;
    top: 57%;
}

.o-pad a.right:after {
    left: 47%;
    top: 53%;
    border-width: 13px 0 13px 19px;
    border-color: transparent transparent transparent #aaa;
}

.o-pad a.right:active:after {
    border-left-color: #333;
}

.o-pad a:hover:after {
    left: 50%;
    top: 50%;
}

.dark .o-pad {
    background: #111;
}

.dark .o-pad a {
    border-color: rgba(255, 255, 255, 0.4);
}

.dark .o-pad a:before {
    display: block;
}

.dark .o-pad:before,
.dark .o-pad a {
    background: #111;
}

.dark .o-pad a.up:after {
    border-bottom-color: rgba(255, 255, 255, 0.9);
}

.dark .o-pad a.right:after {
    border-left-color: rgba(255, 255, 255, 0.9);
}

.dark .o-pad a.down:after {
    border-top-color: rgba(255, 255, 255, 0.9);
}

.dark .o-pad a.left:after {
    border-right-color: rgba(255, 255, 255, 0.9);
}

.dark .o-pad a.up:active:after {
    border-bottom-color: #61e22d;
}

.dark .o-pad a.right:active:after {
    border-left-color: #61e22d;
}

.dark .o-pad a.down:active:after {
    border-top-color: #61e22d;
}

.dark .o-pad a.left:active:after {
    border-right-color: #61e22d;
}
