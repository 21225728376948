.modal {
    --ct-modal-zindex: 1055;
    --ct-modal-width: 500px;
    --ct-modal-padding: 1rem;
    --ct-modal-margin: 0.5rem;
    --ct-modal-bg: var(--ct-secondary-bg);
    --ct-modal-border-color: transparent;
    --ct-modal-border-width: var(--ct-border-width);
    --ct-modal-border-radius: var(--ct-border-radius-lg);
    --ct-modal-box-shadow: 0 0.125rem 0.25rem rgba(var(--ct-body-color-rgb), 0.15);
    --ct-modal-inner-border-radius: calc(var(--ct-border-radius-lg) - (var(--ct-border-width)));
    --ct-modal-header-padding-x: 1rem;
    --ct-modal-header-padding-y: 1rem;
    --ct-modal-header-padding: 1rem 1rem;
    --ct-modal-header-border-color: var(--ct-border-color);
    --ct-modal-header-border-width: var(--ct-border-width);
    --ct-modal-title-line-height: 1.5;
    --ct-modal-footer-gap: 0.5rem;
    --ct-modal-footer-border-color: var(--ct-border-color);
    --ct-modal-footer-border-width: var(--ct-border-width);

    --bs-modal-width: 450px;
}

.modal-right {
    position: absolute;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: var(--ct-secondary-bg);
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-transform: translate(25%, 0) !important;
    transform: translate(25%, 0) !important;
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}

.modal.show .modal-left,
.modal.show .modal-right {
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}

.modal-header .btn-close {
    padding: calc(var(--ct-modal-header-padding-y) * 0.5) calc(var(--ct-modal-header-padding-x) * 0.5);
    margin: calc(-0.5 * var(--ct-modal-header-padding-y)) calc(-0.5 * var(--ct-modal-header-padding-x)) calc(-0.5 * var(--ct-modal-header-padding-y)) auto;
}

.modal-right button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    color: var(--ct-modal-color);
    pointer-events: auto;
    background-color: var(--ct-modal-bg);
    background-clip: padding-box;
    border: var(--ct-modal-border-width) solid var(--ct-modal-border-color);
    border-radius: var(--ct-modal-border-radius);
    outline: 0;
}

.modal-sm {
    --bs-modal-width: 350px;
    width: var(--bs-modal-width);
}

.btn-close-white {
    -webkit-filter: var(--ct-btn-close-white-filter);
    filter: var(--ct-btn-close-white-filter);
}

.modal-header {
    padding: var(--ct-modal-header-padding);
    border-bottom: var(--ct-modal-header-border-width) solid var(--ct-modal-header-border-color);
    border-top-left-radius: var(--ct-modal-inner-border-radius);
    border-top-right-radius: var(--ct-modal-inner-border-radius);
}

.modal-title {
    margin-top: 0;
    color: inherit;
}
.modal-title {
    margin-bottom: 0;
    line-height: var(--ct-modal-title-line-height);
}
