@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;1,6..12,200;1,6..12,300&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
// Popins
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

/* The following line can be included in a src/App.scss */
@import '~bootstrap/scss/bootstrap';

// Custom variables must be set or imported *before* bootstrap/scss is imported.
@import './assets/stylesheets/colors.scss';
@import './assets/stylesheets/custom.scss';
@import './assets/stylesheets/modal.scss';
@import './assets/stylesheets/button.scss';
@import './assets/stylesheets/gamepad.scss';
@import './assets/stylesheets/control.scss';
// @import './assets/stylesheets/gameGUI.scss';
