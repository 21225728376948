.control {
    .content {
        height: calc(100vh - var(--ct-topbar-height));

        .boxer {
            height: calc(100vh - var(--ct-topbar-height));
            display: flex;
        }

        .image {
            flex-grow: 1; /* A imagem ocupa todo o espaço disponível */
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;

            video {
                height: 100%; /* A imagem sempre vai ocupar 100% da altura */
                width: 100%; /* A largura será ajustada automaticamente */
                // object-fit: contain; /* Mantém as proporções sem distorção */
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .conf {
            flex-basis: auto; /* A largura da sidebar será determinada pelo que sobrar */
            width: auto; /* Largura será o que sobrar */
            min-width: 300px; /* Define um mínimo para a sidebar */
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
