@media (min-width: 992px) {
    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%;
    }
}

@media (min-width: 576px) {
    .pb-sm-5 {
        padding-bottom: 4.5rem !important;
    }
}

@media (min-width: 992px) {
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 95%;
    }
}

@media (min-width: 1400px) {
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 85%;
    }
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}

a,
button {
    outline: 0 !important;
}

a {
    color: rgba(var(--ct-link-color-rgb), var(--ct-link-opacity, 1));
    text-decoration: none;
}

.bg-primary {
    --ct-bg-opacity: 1;
    background-color: rgba(var(--ct-primary-rgb), var(--ct-bg-opacity)) !important;
}

.text-muted {
    --ct-text-opacity: 1;
    color: var(--ct-secondary-color) !important;
}

.text-title {
    color: var(--ct-secondary-color);

    &:hover {
        color: var(--ct-tertiary-color);
    }
}

.text-bg-secondary {
    color: #fff !important;
    background-color: rgba(108, 117, 125, var(--ct-bg-opacity, 1)) !important;
}

.py-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
}

.p-4 {
    padding: 2.25rem !important;
}

.p-1 {
    padding: 0.375rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.h4,
h4 {
    font-size: 1.125rem;
}

.h5,
h5 {
    font-size: 0.936rem;
}

.h6,
h6 {
    font-size: 0.765rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    line-height: 1.1;
    color: var(--ct-heading-color);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 10px;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: var(--ct-dropdown-item-padding-y) var(--ct-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--ct-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-radius: var(--ct-dropdown-item-border-radius, 0);
}

.progress-sm {
    height: 5px;
}

.progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: var(--ct-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--ct-progress-bar-bg);
    -webkit-transition: var(--ct-progress-bar-transition);
    transition: var(--ct-progress-bar-transition);
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    height: var(--ct-card-height);
    color: var(--ct-body-color);
    word-wrap: break-word;
    background-color: var(--ct-card-bg);
    background-clip: border-box;
    border: var(--ct-card-border-width) solid var(--ct-card-border-color);
    border-radius: var(--ct-card-border-radius);
}

.card {
    -webkit-box-shadow: var(--ct-box-shadow);
    box-shadow: var(--ct-box-shadow);
    margin-bottom: 1.5rem;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: var(--ct-card-spacer-y) var(--ct-card-spacer-x);
    color: var(--ct-card-color);
}

.badge {
    display: inline-block;
    padding: var(--ct-badge-padding-y) var(--ct-badge-padding-x);
    font-size: var(--ct-badge-font-size);
    font-weight: var(--ct-badge-font-weight);
    line-height: 1;
    color: var(--ct-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--ct-badge-border-radius);
}

.bg-success {
    --ct-bg-opacity: 1;
    background-color: rgba(var(--ct-success-rgb), var(--ct-bg-opacity)) !important;
}

.bg-white {
    --ct-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--ct-bg-opacity)) !important;
}

.badge {
    vertical-align: middle;
}

.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--ct-primary);
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.avatar-sm {
    height: 3rem;
    width: 3rem;
}

.rounded {
    border-radius: var(--ct-border-radius) !important;
}

.text-bg-warning {
    color: #fff !important;
    background-color: RGBA(255, 195, 90, var(--ct-bg-opacity, 1)) !important;
}

.text-bg-secondary {
    color: #fff !important;
    background-color: RGBA(108, 117, 125, var(--ct-bg-opacity, 1)) !important;
}

.text-bg-danger {
    color: #fff !important;
    background-color: RGBA(250, 92, 124, var(--ct-bg-opacity, 1)) !important;
}

.alert {
    --ct-alert-bg: transparent;
    --ct-alert-padding-x: 1.25rem;
    --ct-alert-padding-y: 0.8rem;
    --ct-alert-margin-bottom: 1rem;
    --ct-alert-color: inherit;
    --ct-alert-border-color: transparent;
    --ct-alert-border: var(--ct-border-width) solid var(--ct-alert-border-color);
    --ct-alert-border-radius: 0.25rem;
    --ct-alert-link-color: inherit;
    position: relative;
    padding: var(--ct-alert-padding-y) var(--ct-alert-padding-x);
    margin-bottom: var(--ct-alert-margin-bottom);
    color: var(--ct-alert-color);
    background-color: var(--ct-alert-bg);
    border: var(--ct-alert-border);
    border-radius: var(--ct-alert-border-radius);
}

.alert-primary {
    --ct-alert-color: var(--ct-primary-text-emphasis);
    --ct-alert-bg: var(--ct-primary-bg-subtle);
    --ct-alert-border-color: var(--ct-primary-border-subtle);
    --ct-alert-link-color: var(--ct-primary-text-emphasis);
}


.leaflet-container{
    height: 400px;
    width: 100%;
}