.btn {
    --ct-btn-padding-x: 0.9rem;
    --ct-btn-padding-y: 0.45rem;
    --ct-btn-font-size: 0.9rem;
    --ct-btn-font-weight: 400;
    --ct-btn-line-height: 1.5;
    --ct-btn-color: var(--ct-body-color);
    --ct-btn-bg: transparent;
    --ct-btn-border-width: var(--ct-border-width);
    --ct-btn-border-color: transparent;
    --ct-btn-border-radius: 0.15rem;
    --ct-btn-hover-border-color: transparent;
    --ct-btn-box-shadow: 0px 2px 6px 0px;
    --ct-btn-disabled-opacity: 0.65;
    --ct-btn-focus-box-shadow: 0 0 0 0.15rem rgba(var(--ct-btn-focus-shadow-rgb), 0.5);
    display: inline-block;
    padding: var(--ct-btn-padding-y) var(--ct-btn-padding-x);
    font-family: var(--ct-btn-font-family);
    font-size: var(--ct-btn-font-size);
    font-weight: var(--ct-btn-font-weight);
    line-height: var(--ct-btn-line-height);
    color: var(--ct-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: var(--ct-btn-border-width) solid var(--ct-btn-border-color);
    border-radius: var(--ct-btn-border-radius);
    background-color: var(--ct-btn-bg);
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn:hover {
    color: var(--ct-btn-hover-color);
    background-color: var(--ct-btn-hover-bg);
    border-color: var(--ct-btn-hover-border-color);
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
    color: var(--ct-btn-active-color);
    background-color: var(--ct-btn-active-bg);
    border-color: var(--ct-btn-active-border-color);
}

.btn-light {
    --ct-btn-color: var(--ct-dark);
    --ct-btn-bg: var(--ct-light);
    --ct-btn-border-color: transparent;
    --ct-btn-hover-color: var(--ct-tertiary-color);
    --ct-btn-hover-bg: var(--ct-tertiary-bg);
    --ct-btn-hover-border-color: var(--ct-tertiary-bg);
    --ct-btn-active-bg: var(--ct-tertiary-bg);
    --ct-btn-active-color: var(--ct-tertiary-color);
    --ct-btn-active-border-color: var(--ct-tertiary-bg);
    --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light-rgb), 0.5);
}

.btn-primary {
    --ct-btn-color: #fff;
    --ct-btn-bg: #727cf5;
    --ct-btn-border-color: #727cf5;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #6973e3;
    --ct-btn-hover-border-color: #6973e3;
    --ct-btn-focus-shadow-rgb: 135, 144, 247;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #6973e3;
    --ct-btn-active-border-color: #6973e3;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #fff;
    --ct-btn-disabled-bg: #727cf5;
    --ct-btn-disabled-border-color: #727cf5;
}

.btn-success {
    --ct-btn-color: #fff;
    --ct-btn-bg: #0acf97;
    --ct-btn-border-color: #0acf97;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #09bf8c;
    --ct-btn-hover-border-color: #09bf8c;
    --ct-btn-focus-shadow-rgb: 47, 214, 167;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #09bf8c;
    --ct-btn-active-border-color: #09bf8c;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #fff;
    --ct-btn-disabled-bg: #0acf97;
    --ct-btn-disabled-border-color: #0acf97;
}

.btn-secondary {
    --ct-btn-color: #fff;
    --ct-btn-bg: #6c757d;
    --ct-btn-border-color: #6c757d;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #646c74;
    --ct-btn-hover-border-color: #646c74;
    --ct-btn-focus-shadow-rgb: 130, 138, 145;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #646c74;
    --ct-btn-active-border-color: #646c74;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #fff;
    --ct-btn-disabled-bg: #6c757d;
    --ct-btn-disabled-border-color: #6c757d;
}

.btn-warning {
    --ct-btn-color: #fff;
    --ct-btn-bg: #ffc35a;
    --ct-btn-border-color: #ffc35a;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #ecb453;
    --ct-btn-hover-border-color: #ecb453;
    --ct-btn-focus-shadow-rgb: 255, 204, 115;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #ecb453;
    --ct-btn-active-border-color: #ecb453;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #fff;
    --ct-btn-disabled-bg: #ffc35a;
    --ct-btn-disabled-border-color: #ffc35a;
}

.btn-outline-primary {
    --ct-btn-color: #727cf5;
    --ct-btn-border-color: #727cf5;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #727cf5;
    --ct-btn-hover-border-color: #727cf5;
    --ct-btn-focus-shadow-rgb: 114, 124, 245;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #727cf5;
    --ct-btn-active-border-color: #727cf5;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #727cf5;
    --ct-btn-disabled-bg: transparent;
    --ct-btn-disabled-border-color: #727cf5;
    --ct-gradient: none;
}

.btn-outline-danger {
    --ct-btn-color: #fa5c7c;
    --ct-btn-border-color: #fa5c7c;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #fa5c7c;
    --ct-btn-hover-border-color: #fa5c7c;
    --ct-btn-focus-shadow-rgb: 250, 92, 124;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #fa5c7c;
    --ct-btn-active-border-color: #fa5c7c;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #fa5c7c;
    --ct-btn-disabled-bg: transparent;
    --ct-btn-disabled-border-color: #fa5c7c;
    --ct-gradient: none;
}

.btn-outline-light {
    --ct-btn-color: var(--ct-dark);
    --ct-btn-bg: transparent;
    --ct-btn-border-color: var(--ct-light);
    --ct-btn-hover-color: var(--ct-tertiary-color);
    --ct-btn-hover-bg: var(--ct-tertiary-bg);
    --ct-btn-hover-border-color: var(--ct-tertiary-bg);
    --ct-btn-active-bg: var(--ct-tertiary-bg);
    --ct-btn-active-color: var(--ct-tertiary-color);
    --ct-btn-active-border-color: var(--ct-tertiary-bg);
    --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-light-rgb), 0.5);
}

.btn-outline-dark {
    --ct-btn-color: var(--ct-dark);
    --ct-btn-bg: transparent;
    --ct-btn-border-color: var(--ct-dark);
    --ct-btn-hover-color: var(--ct-light);
    --ct-btn-hover-bg: var(--ct-dark);
    --ct-btn-hover-border-color: var(--ct-dark);
    --ct-btn-active-bg: var(--ct-dark);
    --ct-btn-active-color: var(--ct-light);
    --ct-btn-active-border-color: var(--ct-dark);
    --ct-btn-focus-shadow-rgb: 0 0 0 $btn-focus-width rgba(var(--ct-dark-rgb), 0.5);
}

.btn-outline-secondary {
    --ct-btn-color: #6c757d;
    --ct-btn-border-color: #6c757d;
    --ct-btn-hover-color: #fff;
    --ct-btn-hover-bg: #6c757d;
    --ct-btn-hover-border-color: #6c757d;
    --ct-btn-focus-shadow-rgb: 108, 117, 125;
    --ct-btn-active-color: #fff;
    --ct-btn-active-bg: #6c757d;
    --ct-btn-active-border-color: #6c757d;
    --ct-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --ct-btn-disabled-color: #6c757d;
    --ct-btn-disabled-bg: transparent;
    --ct-btn-disabled-border-color: #6c757d;
    --ct-gradient: none;
}
