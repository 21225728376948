:root {
    --bs-font-sans-serif: 'Nunito', sans-serif;
    --bs-body-font-size: 0.9rem;

    // Colors
    --ct-primary-rgb: 114, 124, 245;

    --ct-secondary-color: rgba(108, 117, 125, 0.75);
    --ct-secondary-bg: #fff;

    --ct-primary: #727cf5;
    --ct-secondary: #6c757d;
    --ct-success: #0acf97;
    --ct-info: #39afd1;
    --ct-warning: #ffc35a;
    --ct-danger: #fa5c7c;
    --ct-light: #eef2f7;
    --ct-dark: #313a46;

    --ct-primary-rgb: 114, 124, 245;
    --ct-secondary-rgb: 108, 117, 125;
    --ct-success-rgb: 10, 207, 151;
    --ct-info-rgb: 57, 175, 209;
    --ct-warning-rgb: 255, 195, 90;
    --ct-danger-rgb: 250, 92, 124;
    --ct-light-rgb: 238, 242, 247;
    --ct-dark-rgb: 49, 58, 70;

    --ct-tertiary-color: #313539;
    --ct-tertiary-color-rgb: 49, 53, 57;
    --ct-tertiary-bg: #f6f7fb;
    --ct-tertiary-bg-rgb: 246, 247, 251;

    --ct-heading-color: inherit;

    --ct-body-color: #6c757d;
    --ct-body-bg: #fafbfe;

    --ct-footer-height: 60px;
    --ct-emphasis-color: rgba(108, 117, 125, 0.75);

    --ct-border-color: #dee2e6;

    --ct-topbar-height: 70px;
    --ct-topbar-item-color: #6c757d;
    --ct-topbar-user-border: #eef2f7;
    --ct-topbar-user-bg: #fafafd;
    --ct-topbar-item-hover-color: #727cf5;
    --ct-topbar-bg: #ffffff;

    --ct-dropdown-item-padding-x: 1.5rem;
    --ct-dropdown-item-padding-y: 0.375rem;
    --ct-dropdown-link-color: var(--ct-body-color);

    --ct-theme-card-border-width: 0px;

    --ct-border-radius: 0.25rem;

    --ct-box-shadow-inset: inset 0 1px 2px rgba(var(--ct-body-color-rgb), 0.075);
    --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);

    // LINK
    --ct-link-color: #727cf5;
    --ct-link-color-rgb: 114, 124, 245;
    --ct-link-decoration: none;
    --ct-link-hover-color: #6169d0;
    --ct-link-hover-color-rgb: 97, 105, 208;

    // PROGRESS
    --ct-progress-height: 1rem;
    --ct-progress-font-size: 0.675rem;
    --ct-progress-bg: var(--ct-tertiary-bg);
    --ct-progress-border-radius: var(--ct-border-radius);
    --ct-progress-box-shadow: var(--ct-box-shadow-inset);
    --ct-progress-bar-color: #fff;
    --ct-progress-bar-bg: #727cf5;
    --ct-progress-bar-transition: width 0.6s ease;

    // CARD
    --ct-card-spacer-y: 1.5rem;
    --ct-card-spacer-x: 1.5rem;
    --ct-card-title-spacer-y: 0.75rem;
    --ct-card-border-width: var(--ct-theme-card-border-width);
    --ct-card-border-color: var(--ct-border-color);
    --ct-card-border-radius: var(--ct-border-radius);
    --ct-card-box-shadow: var(--ct-box-shadow);
    --ct-card-inner-border-radius: calc(var(--ct-border-radius) - (var(--ct-theme-card-border-width)));
    --ct-card-cap-padding-y: 1.125rem;
    --ct-card-cap-padding-x: 1.5rem;
    --ct-card-cap-bg: transparent;
    --ct-card-bg: var(--ct-secondary-bg);
    --ct-card-img-overlay-padding: 1.5rem;
    --ct-card-group-margin: 0.75rem;

    // BAD
    --ct-badge-padding-x: 0.4em;
    --ct-badge-padding-y: 0.25em;
    --ct-badge-font-size: 0.75em;
    --ct-badge-font-weight: 700;
    --ct-badge-color: #fff;
    --ct-badge-border-radius: 0.25rem;

    --ct-border-width: 1px;
    --ct-border-style: solid;
    --ct-border-color: #dee2e6;
    --ct-border-color-translucent: #dee2e6;
    --ct-border-radius: 0.25rem;
    --ct-border-radius-sm: 0.2rem;
    --ct-border-radius-lg: 0.3rem;
    --ct-border-radius-xl: 1rem;
    --ct-border-radius-xxl: 2rem;
    --ct-border-radius-2xl: var(--ct-border-radius-xxl);
    --ct-border-radius-pill: 50rem;

    --bs-btn-close-color: --ct-secondary-color;

    --ct-primary-text-emphasis: #2e3262;
    --ct-primary-bg-subtle: #f1f2fe;
    --ct-primary-border-subtle: #e3e5fd;
    --ct-primary-text-emphasis: #2e3262;
}

body {
    color: var(--ct-body-color);
    background-color: var(--ct-body-bg);
}
